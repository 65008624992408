"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbMediaPlayerContext = void 0;
// HLS Video Player
// GitHub Home - https://github.com/video-dev/hls.js
// Design Info (Events) - https://github.com/video-dev/hls.js/blob/master/docs/design.md
// Demo - https://hlsjs.video-dev.org/demo/
const api_1 = require("@soundbite/api");
const store_1 = require("../../../store");
const MediaPlayerContext_1 = require("./MediaPlayerContext");
// References the Hls library
let Hls = window.Hls;
/**
 * Soundbite Media Player implementation (for use with azure storage hosted HTfiles)
 */
class SbMediaPlayerContext extends MediaPlayerContext_1.MediaPlayerContext {
    //////////[ Constructor ]/////////////////////////////////////////////////////////////////////////
    constructor() {
        super("hlsstreaming");
        this.isNative = false;
    }
    //////////[ Overrides ]///////////////////////////////////////////////////////////////////////////
    jumpTo(time) {
        if (this.mediaElement) {
            this.mediaElement.currentTime = time;
        }
    }
    load(clip, mediaUrl, autoPlay, isPublic) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            if (clip) {
                this.isPublic = isPublic !== null && isPublic !== void 0 ? isPublic : false;
                // Validate that we have an Hls reference
                Hls = Hls !== null && Hls !== void 0 ? Hls : window.Hls;
                if (!Hls) {
                    const msg = "Hls library is not loaded or is otherwise inexcessible.";
                    api_1.Logger.LogError(msg);
                    throw new Error(msg);
                }
                var token = "";
                // Acquire the URL
                if (mediaUrl == undefined || mediaUrl == "" || mediaUrl == null) {
                    const orgRoute = (_c = (_b = (_a = store_1.WidgetStore.organizations.currentOrg) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.route) !== null && _c !== void 0 ? _c : store_1.WidgetStore.organizations.currentOrgRoute;
                    if (orgRoute == null) {
                        console.error("Could not find org route when SbMediaPlayerContext is loading clip", clip.route);
                    }
                    let info = yield api_1.StreamingMediaProxyService.sbClipManifest(orgRoute !== null && orgRoute !== void 0 ? orgRoute : "", clip.route);
                    mediaUrl = `${info.url}?${info.token}`;
                    if (info.token) {
                        token = `?${info.token}`;
                    }
                    //mediaUrl = "https://sbdeverik.blob.core.windows.net/orge5f75632-637d-4d2e-a8a7-e8f6993acb74/ses$ilcp2oq3pve0o2xkyz6tp/pro7ls5jf6ebdjcubwsgqig5/clpbo2gkbp934gw38f8wbvoy/playlist.m3u8?cb=3";        
                }
                //////////////////////////////////////////////////////////////////////////////////////////////
                // The possibility exists to play files natively, but we are currently relying on the HLS
                // event model to inject our tokens into the playlists.  So native playback will not work.
                // If we switched to injecting tokens on the server side native playback may work, but if
                // all targeted devices can play the HLS player then we can get reliable playback data from
                // the HLS player for metrics.  Leaving the code below for now in case it becomes useful in
                // the future or if we determine the HLS player doesn't work on some devices and are forced
                // to use the native player.
                //////////////////////////////////////////////////////////////////////////////////////////////
                // // Determine whether the browser has the native ability to play an apple formatted M3U8 file
                // if (this.mediaElement.canPlayType("application/vnd.apple.mpegurl")) {
                //   this.isNative = true;
                //   this.initializeVideoElement(autoPlay ?? false);
                //   this.mediaElement.src = mediaUrl;
                // }
                //////////////////////////////////////////////////////////////////////////////////////////////
                if (Hls.isSupported()) {
                    this.isNative = false;
                    this.initializeHlsPlayer(autoPlay !== null && autoPlay !== void 0 ? autoPlay : false);
                    this.hlsPlayer = new Hls();
                    this.hlsPlayer.on(Hls.Events.MANIFEST_LOADED, function (e, data) {
                        //debugger;
                        //// Main manifest was loaded -- update levels with appropriate
                        //if (data && data.levels && data.levels.length > 0) {
                        //  for (let i = 0; i < data.levels.length; i++) {              
                        //    data.levels[i].url = data.levels[i].url.replace("{TOKEN}", token);
                        //  }
                        //}
                    });
                    this.hlsPlayer.on(Hls.Events.MANIFEST_PARSED, function (e, data) {
                        debugger;
                        // Main manifest was loaded -- update levels with appropriate
                        if (data && data.levels && data.levels.length > 0) {
                            for (let i = 0; i < data.levels.length; i++) {
                                if (data.levels[i].url && data.levels[i].url.length > 0) {
                                    for (let j = 0; j < data.levels[i].url.length; j++) {
                                        data.levels[i].url[j] = data.levels[i].url[j].replace("{TOKEN}", token);
                                    }
                                }
                            }
                        }
                    });
                    this.hlsPlayer.on(Hls.Events.LEVEL_LOADED, function (e, data) {
                        debugger;
                        // Loading of a quality level has completed so we need to update
                        for (let i = 0; i < data.details.fragments.length; i++) {
                            data.details.fragments[i].url = data.details.fragments[i].url.replace("{TOKEN}", token);
                        }
                    });
                    this.hlsPlayer.loadSource(mediaUrl);
                    this.hlsPlayer.attachMedia(this.mediaElement);
                }
                else {
                    // No way to play video so log/throw an exception
                    const msg = "Hls and Native Video is not supported. Cannot play video.";
                    api_1.Logger.LogError(msg);
                    throw new Error(msg);
                }
            }
        });
    }
    play() {
        if (this.mediaElement) {
            this.mediaElement.play();
            this.isPlaying = true;
            if (this.onPlay) {
                this.onPlay();
            }
        }
    }
    pause() {
        if (this.mediaElement) {
            this.mediaElement.pause();
            this.isPlaying = false;
        }
    }
    //////////[ Methods ]/////////////////////////////////////////////////////////////////////////////
    initializeHlsPlayer(autoPlay) {
        this.initializeVideoElement(autoPlay);
    }
    initializeVideoElement(autoPlay) {
        if (this.mediaElement) {
            // Setup auto play (if requested)
            this.mediaElement.addEventListener("canplay", () => __awaiter(this, void 0, void 0, function* () {
                const autoPlayAllowed = yield this.autoPlayAllowed();
                if (autoPlay && autoPlayAllowed) {
                    this.play();
                }
            }));
            // Handle durationchange event
            this.mediaElement.addEventListener("durationchange", () => {
                this.handleDurationChange();
            });
            // Handle timeupdate event
            this.mediaElement.addEventListener("timeupdate", () => {
                this.handleTimeUpdate();
            });
            // Handle play event
            this.mediaElement.addEventListener("play", () => {
                this.handleOnPlay();
            });
            // Handle pause event
            this.mediaElement.addEventListener("pause", () => {
                this.handleOnPause();
            });
            // Handle ended event
            this.mediaElement.addEventListener("ended", () => {
                this.handleEnded();
            });
        }
        else {
            const msg = "Cannot initialize video element because the Video element is not referenced.";
            api_1.Logger.LogError(msg);
            throw new Error(msg);
        }
    }
    handleTimeUpdate() {
        this.time = this.mediaElement.currentTime;
    }
    handleDurationChange() {
        const newDuration = this.mediaElement.duration;
        if (!Number.isNaN(newDuration) && Number.isFinite(newDuration)) {
            this.duration = newDuration;
        }
    }
    handleOnPlay() {
        if (!this.isPlaying) {
            this.isPlaying = true;
        }
    }
    handleOnPause() {
        if (this.isPlaying) {
            this.isPlaying = false;
        }
    }
    handleEnded() {
        // Ended can fire when the end of the media is encountered OR the data stream stops. When
        // encoutering the end of the video, the Azure Media Player seems to rewind the video to
        // the zero mark.  I have NOT encountered a data stream termianting the player, so my
        // ASSUMPTION is that the player does not reset when encountering a data issue, but I may
        // very well be wrong.
        if (this.mediaElement.currentTime === 0) {
            if (this.onPlaybackComplete) {
                this.onPlaybackComplete();
            }
        }
    }
}
exports.SbMediaPlayerContext = SbMediaPlayerContext;
